<template>
  <app-list-view
    server-side
    app="liability"
    model="liability"
    api-url="liability/liability/"
    :title="$t('menu.liability')"
    :createTo="{ name: 'liabilityCreate' }"
    :editTo="{ name: 'liabilityEdit' }"
    :headers="headers"
  />
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'liabilityCategoryList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.company'), value: 'company.name' },
        { text: this.$t('fields.liabilityName'), value: 'name' },
        {
          text: this.$t('fields.liabilityCategory'),
          value: 'liability_category.name'
        },
        { text: this.$t('fields.liabilityType'), value: 'liability_type.name' },
        {
          text: this.$t('fields.liabilityGroup'),
          value: 'liability_group.name'
        },
        { text: this.$t('fields.date'), value: 'date', isDate: true },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'liability'
    })
  }
}
</script>
